<template>
  <div class="xtx-topic">
    <!-- 整体 -->
    <div class="box" v-for="item in ggoods" :key="item" style="margin-top: 2%">
      <div class="boxTitle">
        <div class="boxTitleLeft">{{item.title}}</div>
        <div class="boxTitleRight">
          <div class="left">
            <!-- !!! v-for 嵌套使用 -->
            <div v-for="a in item.sublist" :key="a">{{a}}</div>
           <!--  <div>茶咖酒具</div>
            <div>茶咖酒具</div>
            <div>茶咖酒具</div> -->
          </div>
          <div class="right" style="color: #999999">{{item.count}} ></div>
        </div>
      </div>
      <div class="boxBottom">
        <!-- 左 -->
        <div class="boxLeft">
          <img :src="item.bigimg" alt="" />

          <!-- 固定栏 -->
          <div class="flow">
            <div>{{item.sst}}</div>
            <div>{{item.ssp}}</div>
          </div>
        </div>
        <!-- 右 -->
        <div class="boxRight">
          <div class="goods" v-for="item in 8" :key="item">
            <div class="goodsTop">
              <img src="@/assets/img/goods1.jpg" alt="" />
            </div>

            <div class="goodsBottom">
              <div>日式黑陶功夫茶组双侧把茶具礼盒装</div>
              <div>日式风格单把设计防烫隔热美观大气</div>
              <div class="price">¥288.00</div>
            </div>

            <!-- 悬浮显示的盒子 -->
            <div class="flowbox">
              <div>
                <div style="border-bottom: 1px solid #fff; width: 50%">
                  找相视
                </div>
                <div style="margin-top: -5%">发现更多宝贝 ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ggoods:[
      
        {
          title: "居家",
          sublist: ["茶咖酒具", "水具杯壶", "宠物食品", "茶咖酒具"],
          count: "查看全部",
          bigimg:
            "https://yanxuan-item.nosdn.127.net/46898c7fa475dbfc89dac2f7e7c2c16f.jpg",
          sst:"居家馆",
          ssp:"全场3件8折",
          simg: [
            "https://yanxuan-item.nosdn.127.net/3346b7b92f9563c7a7e24c7ead883f18.jpg",
            "https://yanxuan-item.nosdn.127.net/3346b7b92f9563c7a7e24c7ead883f18.jpg",
            "https://yanxuan-item.nosdn.127.net/4356c9fc150753775fe56b465314f1eb.png",
          ],
          stitle: [
            "魔术盒快客杯旅行盖碗茶具套装",
            "五谷丰登好兆头白酒杯壶套装一壶五杯+骰子",
            "日式黑陶功夫茶组双侧把茶具礼盒装",
            "大师监制龙泉青瓷茶叶罐",
            "电动红酒醒酒器分酒器取酒器",
            "非遗大师手作标本级六色建盏套组",
            "竹制干泡茶盘正方形沥水茶台品茶盘",
            "四季木叶盏年度臻选套装",
          ],
          detail: [
            "轻松收纳携带方便随时随地品茶",
            "纯手工精制，每一个细节都精益求精，源自20多年经验的老师傅精心手作",
            "日式风格单把设计防烫隔热美观大气",
            "世界非遗|青瓷世家出品|釉润如玉",
            "贴心醒酒阀设计，随心开启，自由切换、轻松开启和关闭醒酒器功能",
            "用低价一次集齐六色建盏，大师手作，盏盏精美",
            "干泡蓄水拆洗方便轻便实用",
            "银杏、拉拉草、杨树叶、枫叶木叶盏甄选。四种杯型，形态、寓意皆不同，四只一套，记录非遗技艺发展过程中的美丽成果。",
          ],
          price: [
            "¥148.00",
            "¥188.00",
            "¥288.00",
            "¥139.00",
            "¥169.00",
            "¥439.00",
            "¥109.00",
            "¥349.00",
          ],
        },
      
      
        {
          title: "服饰",
          sublist: ["茶咖酒具", "水具杯壶", "宠物食品", "茶咖酒具"],
          count: "查看全部",
          bigimg:
            "https://yanxuan-item.nosdn.127.net/46898c7fa475dbfc89dac2f7e7c2c16f.jpg",
          sst:"居家馆",
          ssp:"全场3件8折",
          simg: [
            "https://yanxuan-item.nosdn.127.net/3346b7b92f9563c7a7e24c7ead883f18.jpg",
            "https://yanxuan-item.nosdn.127.net/3346b7b92f9563c7a7e24c7ead883f18.jpg",
            "https://yanxuan-item.nosdn.127.net/4356c9fc150753775fe56b465314f1eb.png",
          ],
          stitle: [
            "魔术盒快客杯旅行盖碗茶具套装",
            "五谷丰登好兆头白酒杯壶套装一壶五杯+骰子",
            "日式黑陶功夫茶组双侧把茶具礼盒装",
            "大师监制龙泉青瓷茶叶罐",
            "电动红酒醒酒器分酒器取酒器",
            "非遗大师手作标本级六色建盏套组",
            "竹制干泡茶盘正方形沥水茶台品茶盘",
            "四季木叶盏年度臻选套装",
          ],
          detail: [
            "轻松收纳携带方便随时随地品茶",
            "纯手工精制，每一个细节都精益求精，源自20多年经验的老师傅精心手作",
            "日式风格单把设计防烫隔热美观大气",
            "世界非遗|青瓷世家出品|釉润如玉",
            "贴心醒酒阀设计，随心开启，自由切换、轻松开启和关闭醒酒器功能",
            "用低价一次集齐六色建盏，大师手作，盏盏精美",
            "干泡蓄水拆洗方便轻便实用",
            "银杏、拉拉草、杨树叶、枫叶木叶盏甄选。四种杯型，形态、寓意皆不同，四只一套，记录非遗技艺发展过程中的美丽成果。",
          ],
          price: [
            "¥148.00",
            "¥188.00",
            "¥288.00",
            "¥139.00",
            "¥169.00",
            "¥439.00",
            "¥109.00",
            "¥349.00",
          ],
        },
      
      ]
    };
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 98%;
  margin: auto;

  // display: flex;
  .boxTitle {
    display: flex;

    .boxTitleLeft {
      width: 63%;
      font-size: 43px;
      //   background-color: #5aba9b;
      display: flex;
      align-items: center;
      line-height: 109px;
    }

    .boxTitleRight {
      //   background-color: #ccc;
      width: 37%;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;

      .left {
        display: flex;
        width: 70%;
        // background-color: #fff;
        justify-content: space-around;
      }

      .right {
      }
    }
  }
  .boxBottom {
    // width: 100%;
    display: flex;

    .boxLeft {
      // height: 600px;
      width: 20%;
      background-color: red;
      overflow: hidden;
      position: relative;

      // 图片不拉伸，显示图片中间的内容，溢出(父标签的样式)盒子的部分隐藏
      img {
        width: 100%; /* 图片宽度 */
        height: 100%; /* 图片高度 */
        object-fit: cover; /* 不拉伸图片，覆盖整个容器 */
        clip-path: inset(0 0 0 0); /* 初始剪裁路径 */
        transition: clip-path 0.3s ease; /* 平滑过渡效果 */
      }

      .flow {
        width: 100%;
        // background-color: #5aba9b;
        // height: 35px;
        position: absolute;
        z-index: 1000;
        top: 50%;
        display: flex;
        color: #fff;
        font-size: 1.375rem;
        line-height: 4rem;
        text-align: center;
      }
      .flow > div:nth-child(1) {
        background-color: #000000e6;
        width: 43%;
        height: 62px;
      }
      .flow > div:nth-child(2) {
        background-color: #000000b3;
        width: 43%;
        height: 62px;
      }
    }

    .boxRight {
      height: 39rem;
      width: 80%;
      //   background-color: pink;
      padding: 1%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      flex-direction: column;

      .goods {
        // background-color: red;
        width: 20%;
        height: 47%;
        // border: 1px solid #27ba9b;
        padding-top: 1%;
        position: relative;

        .flowbox {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          border: 1px solid #27ba9b;
          display: flex;
          align-items: flex-end;
          opacity: 0;
          // display: none;
          div {
            background-color: #27ba9b;
            width: 100%;
            height: 34%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            color: #fff;
            // line-height: 11px;
          }
        }
        .flowbox:hover {
          opacity: 1;
        }
      }
      .goodsTop {
        display: flex;
        justify-content: space-around;

        img {
          width: 74%;
        }
      }

      .goodsBottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 25px;
        width: 92%;
        margin: auto;

        .price {
          font-size: 23px;
          margin-top: 3%;
          color: red;
          margin-left: -60%;
        }
      }
      // goodsBottom类名下的第二个子div
      .goodsBottom > div:nth-child(2) {
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        // 溢出的字用省略号替代
        white-space: nowrap;
        width: 100%;
      }
    }
  }
}
</style>