<template>
  <div class="xtx-newgoods" style="margin-top: 2%;">
    <div class="goodTitle">
      <div class="goodTitle-left">
        <div>热门品牌</div>
      </div>

      <div class="goodTitle-right">
        <div>国际经典 品质保证</div>
        <!-- <div>查看全部 ></div> -->
      </div>
    </div>
    <div class="goodsmain">
      <div class="goods" v-for="item in list" :key="item">
        <!-- 商品图品 -->
        <div class="goods-img">
          <img :src="item" alt="" />
        </div>
        <!-- 商品标题和价格 -->

        <!-- <div class="goods-info">
          <div class="goods-title">
            特惠推荐
          </div>
          <div class="goods-price">它们最实惠</div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      list:['http://yjy-xiaotuxian-dev.oss-cn-beijing.aliyuncs.com/picture/2021-04-22/a25d210a-cf3d-49f5-9006-5c1e7c563bb9.jpg',
      'http://yjy-xiaotuxian-dev.oss-cn-beijing.aliyuncs.com/picture/2021-04-22/b5fd7624-1d1e-4ed9-b739-a8f44f2d08fc.jpg',
      'http://yjy-xiaotuxian-dev.oss-cn-beijing.aliyuncs.com/picture/2021-04-22/fdbc5113-dcca-4b7f-b1d6-c51faf8e3de9.jpg',
      'http://yjy-xiaotuxian-dev.oss-cn-beijing.aliyuncs.com/picture/2021-04-22/b5fd7624-1d1e-4ed9-b739-a8f44f2d08fc.jpg']
    }
     
  }
};
</script>

<style lang="less" scoped>
.xtx-newgoods {
  width: 100%;
  background-color: #fff;

  .goodTitle {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;

    .goodTitle-left {
      font-size: 38px;
      width: 20%;
    }

    .goodTitle-right {
      width: 85%;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      color: #999999;
      align-items: flex-end;
    }
  }

  .goodsmain {
    // height: 366px;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    
    // .goods:hover{
    //     box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); /* 左边和下边有阴影 */
    //      transform: translateY(-5px); /* 向上浮动一点点 */
    // }
    .goods {
      width: 24%;
    //   background-color: #f0f9f4;
      height: 100%;

      img {
        // height: 220px;
        width: 100%;
      }
      .goods-info {
        text-align: center;
        // padding: 3%;
        font-size: 19px;
            margin: 5%;

        .goods-title {
          overflow: hidden;
          text-overflow: ellipsis;
          // 文字溢出部分用省略号替代
          white-space: nowrap;
        }
        .goods-price {
          margin-top: 3%;
          color: #999999;
        }
      }
    }
  }
}
</style>