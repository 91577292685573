<template>
  <div class="footer" style="margin-top: 3%;">
    <div class="main">
        
    <div class="top">
        <div v-for="item in 3" :key="item">价格亲民</div>
    </div>

    <div class="bottom">
        <div class="bt">
        <div v-for="item in footerTile" :key="item">{{item}}</div>
        <div style="border:none;">友情链接</div>
        </div>
        <div>CopyRight © 小兔鲜儿</div>
    </div>
    
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            footerTile:['关于我们','帮助中心','售后中心','配送与验收','商务合作','搜索推荐']
        }
    }
}
</script>

<style lang="less" scoped>
.footer{
    background-color: #333333;
    width: 100%;
    height: 42vh;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .main{
        // background-color: red;
    width: 100%;
    height: 100%;

    .top{
        // background-color: pink;
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    font-size: 30px;
    font-weight: 500;
        border-bottom: 1px solid #999999;
    }
    
    .bottom{
        //   background-color: #fff;
    width: 100%;
    height: 50%;
    color: #999999;
    display: flex;
    font-size: 1rem;
    flex-direction: column;
    align-items: center;

    .bt{
       
    display: flex;
    width: 60%;
    // background-color: red;
    justify-content: space-around;
    /* margin: 2%; */
    margin: 3% 0 2% 0;

        div{
            border-right: 1px solid #999999;
    padding-right: 3%;
        }
    }
    

    }
    }
    
    }

</style>