<template>
  <div class="App">
    <!-- 快捷链接 -->
    <XtxShortCut></XtxShortCut>
    <!-- 顶部导航 -->
    <XtxHeaderNav></XtxHeaderNav>
    <!-- 轮播区域 -->
   <XtxBanner></XtxBanner>
    <!-- 新鲜好物 -->
   <XtxNewGoods></XtxNewGoods>
   <!-- 人气推荐 -->
   <XtxRecommended></XtxRecommended>
    <!-- 热门品牌 -->
   <XtxHotBrand></XtxHotBrand>
    <!-- 最新专题 -->
    <XtxTopic></XtxTopic>
    <!-- 底部 -->
    <XtxFooter></XtxFooter>
  </div>
</template>

<script>

import XtxShortCut from "./components/XtxShortCut.vue";
import XtxHeaderNav from "./components/XtxHeaderNav.vue";
import XtxBanner from "./components/XtxBanner.vue";
import XtxNewGoods from "./components/XtxNewGoods.vue";
import XtxRecommended from "./components/XtxRecommended.vue";
import XtxHotBrand from './components/XtxHotBrand.vue';
import XtxTopic from './components/XtxTopic.vue';
import XtxFooter from './components/XtxFooter.vue';

export default {
  components: {
    XtxShortCut,
    XtxHeaderNav,
    XtxBanner,
    XtxNewGoods,
    XtxRecommended,
    XtxHotBrand,
    XtxTopic,
    XtxFooter
    
  },
};
</script>

<style>
</style>