<template>
  <div class="xtx-newgoods" style="margin-top: 2%;">
    <div class="goodTitle">
      <div class="goodTitle-left">
        <div>人气推荐</div>
      </div>

      <div class="goodTitle-right">
        <div>人气爆款 不容错过</div>
        <!-- <div>查看全部 ></div> -->
      </div>
    </div>
    <div class="goodsmain">
      <div class="goods" v-for="item in goods" :key="item">
        <!-- 商品图品 -->
        <div class="goods-img">
          <img :src="item.img" alt="" />
        </div>
        <!-- 商品标题和价格 -->

        <div class="goods-info">
          <div class="goods-title">
            {{item.title}}
          </div>
          <div class="goods-price">{{item.detail}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {

      goods:[
        {
          img:'https://yjy-oss-files.oss-cn-zhangjiakou.aliyuncs.com/tuxian/popular_1.jpg',
          titele:'特惠推荐',
          detail:'它们最实惠'
        },
        {
          img:'https://yjy-oss-files.oss-cn-zhangjiakou.aliyuncs.com/tuxian/popular_2.jpg',
          titele:'爆款推荐',
          detail:'它们最受欢迎'
        },
        {
          img:'https://yjy-oss-files.oss-cn-zhangjiakou.aliyuncs.com/tuxian/popular_3.jpg',
          titele:'一站买全',
          detail:'使用场景下精心优选'
        },
        {
          img:'https://yjy-oss-files.oss-cn-zhangjiakou.aliyuncs.com/tuxian/popular_4.jpg',
          titele:'领券中心',
          detail:'更多超值优惠券'
        }
      ]
    }
  }
};
</script>

<style lang="less" scoped>
.xtx-newgoods {
  width: 100%;
  background-color: #fff;

  .goodTitle {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;

    .goodTitle-left {
      font-size: 38px;
      width: 20%;
    }

    .goodTitle-right {
      width: 85%;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      color: #999999;
      align-items: flex-end;
    }
  }

  .goodsmain {
    // height: 366px;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    
    .goods:hover{
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2); /* 左边和下边有阴影 */
         transform: translateY(-5px); /* 向上浮动一点点 */
    }
    .goods {
      width: 24%;
    //   background-color: #f0f9f4;
      height: 100%;

      img {
        // height: 220px;
        width: 100%;
      }
      .goods-info {
        text-align: center;
        // padding: 3%;
        font-size: 19px;
            margin: 5%;

        .goods-title {
          overflow: hidden;
          text-overflow: ellipsis;
          // 文字溢出部分用省略号替代
          white-space: nowrap;
        }
        .goods-price {
          margin-top: 3%;
          color: #999999;
        }
      }
    }
  }
}
</style>