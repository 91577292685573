<template>
  <div class="xtx-shortcut">
    <!-- 快捷链接 -->
    <div><a href="javascript:;">请先登录</a> </div>
   <div> <a href="javascript:;">免费注册</a></div>
   <div> <a href="javascript:;">我的订单</a></div>
   <div> <a href="javascript:;">会员中心</a></div>
   <div> <a href="javascript:;">帮助中心</a></div>
   <div> <a href="javascript:;">关于我们</a></div>
   <div> <a href="javascript:;" style="border-right:none">手机版</a></div>
   
    
  </div>
</template>

<script>
export default {
    
};
</script>

<style lang="less">
.xtx-shortcut {
    width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  min-width: 1000px;
  background-color: #333333;
  a {
    color: #cdcdcd;
    padding: 0 13px;
    text-decoration: none;
    font-size: 14px;
    border-right: 1px solid #cccccc;
  }
}
</style>