<template>
  <div class="xtx-newgoods" style="margin-top: 2%;">
    <div class="goodTitle">
      <div class="goodTitle-left">
        <div>新鲜好物</div>
      </div>

      <div class="goodTitle-right">
        <div>新鲜出炉 品质靠谱</div>
        <div>查看全部 ></div>
      </div>
    </div>
    <div class="goodsmain">
      <div class="goods" v-for="item in goods" :key="item">
        <!-- 商品图品 -->
        <div class="goods-img">
          <img :src="item.img" alt="" />
        </div>
        <!-- 商品标题和价格 -->

        <div class="goods-info">
          <div class="goods-title">
            {{ item.title }}
          </div>
          <div class="goods-price">￥{{item.price}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      goods:[
        {
          img: 'https://yanxuan-item.nosdn.127.net/46898c7fa475dbfc89dac2f7e7c2c16f.jpg',
          title: '魔术盒快客杯旅行盖碗茶具套装',
          price: '148'
        },
        {
          img: 'https://yanxuan-item.nosdn.127.net/605d71caa460e3c05cb2fd1b2885b9b4.jpg',
          title: '儿童字母纯棉POLO衫73-130cm',
          price: '99'
        },
        {
          img: 'https://yanxuan-item.nosdn.127.net/ca17e384dc1c005c24c06e1abfde6ab4.jpg',
          title: '复古文艺宝宝格纹短袖衬衫73-110cm',
          price: '89'
        },
        {
          img: 'https://yanxuan-item.nosdn.127.net/e7337596de7161c57c27e8d8040231aa.jpg',
          title: '情侣款时尚户外轻型徒步鞋环保大底',
          price: '364'
        }
      ]
      }
    }
}
</script>

<style lang="less" scoped>
.xtx-newgoods {
  width: 100%;

  .goodTitle {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;

    .goodTitle-left {
      font-size: 38px;
      width: 20%;
    }

    .goodTitle-right {
      width: 85%;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      color: #999999;
      align-items: flex-end;
    }
  }

  .goodsmain {
    // height: 366px;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-around;

    .goods {
      width: 24%;
      background-color: #f0f9f4;
      height: 100%;

      img {
        // height: 220px;
        width: 100%;
      }
      .goods-info {
        text-align: center;
        // padding: 3%;
        font-size: 19px;
            margin: 5%;

        .goods-title {
          overflow: hidden;
          text-overflow: ellipsis;
          // 文字溢出部分用省略号替代
          white-space: nowrap;
          // 一定要加宽度
          width: 100%;
        }
        .goods-price {
          margin-top: 3%;
          color: red;
        }
      }
    }
  }
}
</style>