<template>
<!-- 轮播图区域· -->
  <div class="xtx-banner">
    <div class="xtx-banner-box">
    <ul class="xtx-banner-imgs">
        <li><img src="@/assets/img/image11.jpg" alt=""></li>
        <li><img src="@/assets/img/image12.jpg" alt=""></li>
        <li><img src="@/assets/img/image13.png" alt=""></li>
    </ul>

    <div class="xtx-banner-menu">
      <ul v-for='item in 9' :key="item">
        <li>
          <a href="#">居家</a>
          <a href="#">茶咖酒具</a>
          <a href="#">水具被壶</a>
        </li>
      </ul>
    </div>

    <div class="curren">
        <p>分类了推荐<span>根据您的购买或浏览记录推荐</span></p>
        <div class="goods" v-for="item in 9" :key="item"  >
          <img src="@/assets/img/home1.png" alt="">
          <div class="good-left">
            <span>圆润大肚流线型耐热玻璃凉水壶</span>
            <span>1.25L容量，耐冷热，通...</span>
            <span class="price">￥79.00</span>
          </div>
        </div>
    </div>

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang='less'>
.xtx-banner{
    position: relative; 
    width:100%;  /* 设置轮播图的宽度 */
    overflow: hidden;  /* 隐藏超出的内容 */
    height: 500px;
    margin: 0 auto;
    background-color: #f5f5f5;
    .xtx-banner-box{
      position: absolute;
      height: 500px;
      width: 1240px;
      left: 50%;
      margin-left: -620px;
      overflow: hidden;//超出的部分隐藏
    .xtx-banner-imgs{
       display: flex;
       position: absolute;
       top:0;
       margin: 0;
       padding: 0;
       height: 100%;
       width: 100%;       /* 幻灯片数量 * 轮播图宽度 */
       min-width: 100px;
       min-height: 150px;
       animation: slide 10s infinite;  /* 动画名称，持续时间，是否无限循环 infinite(无限循环) */
       li{
        width: 1240px;
        height: 500px;
        flex:1;    /* 幻灯片数量 * 轮播图宽度 */
        list-style: none;
        background-size: cover;
       }
    }
    // 轮播图的动画定义
    @keyframes slide{
         0% { transform: translateX(0); }
  20% { transform: translateX(0); } /* 第一张图片停留 30 秒 */
  22% { transform: translateX(-100%); } /* 切换时间 2 秒 */
  42% { transform: translateX(-100%); } /* 第二张图片停留 30 秒 */
  44% { transform: translateX(-200%); } /* 切换时间 2 秒 */
  64% { transform: translateX(-200%); } /* 第三张图片停留 30 秒 */
  66% { transform: translateX(0); } /* 回到初始位置 */
}
  .xtx-banner-menu{
    background-color: rgba(0, 0, 0, 0.7);
    width: 269px;
    height: 500px;
    z-index: 1;
    position: absolute;
    margin: auto;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    li {
      list-style: none;
      margin: auto;
    a{
      text-decoration: none;
      color: #fff;
    }
    }
  }
  .curren{
    background-color: #fff;
    .goods{
     
    width: 275px;
        display: inline-block;
    img{
          width: 33%;
    }
    .good-left{
          display: flex;
    flex-direction: column;
    }
    }
  }
}
}
</style>