var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xtx-headernav"},[_vm._m(0),_c('div',{staticClass:"nav-center"},[_c('ul',[_c('li',[_c('a',{on:{"mouseover":_vm.onMouseOver,"mouseout":_vm.onMouseOut}},[_vm._v("首页")])]),_vm._m(1),_vm._m(2),_c('li',[_c('a',{on:{"mouseover":_vm.onMouseOver,"mouseout":_vm.onMouseOut}},[_vm._v("服饰")])]),_vm._m(3),_vm._m(4),_c('li',[_c('a',{on:{"mouseover":_vm.onMouseOver,"mouseout":_vm.onMouseOut}},[_vm._v("严选")])]),_vm._m(5),_vm._m(6),_vm._m(7)])]),_vm._m(8),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.floatShow),expression:"floatShow"}],staticClass:"xtx-headernav-float"},_vm._l((5),function(item){return _c('div',{key:item},[_c('img',{attrs:{"src":require("@/assets/img/fresh8.png"),"alt":""}}),_c('p',[_vm._v("茶咖酒具")])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-left"},[_c('img',{attrs:{"src":require("@/assets/img/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_vm._v("居家")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_vm._v("美食")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_vm._v("母婴")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_vm._v("个护")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_vm._v("数码")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_vm._v("运动")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',[_vm._v("杂项")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-right"},[_c('input',{attrs:{"type":"text","placeholder":"搜一搜"}})])
}]

export { render, staticRenderFns }