<template>
  <!-- 顶部导航栏 -->
  <div class="xtx-headernav">
    <div class="nav-left">
      <img src="@/assets/img/logo.png" alt="" />
    </div>

    <div class="nav-center">
      <ul>
        <!--@mouseover鼠标悬浮事件；@mouseout鼠标离开事件  -->
        <li><a @mouseover="onMouseOver" @mouseout="onMouseOut">首页</a></li>
        <li><a>居家</a></li>
        <li><a>美食</a></li>
        <li><a @mouseover="onMouseOver" @mouseout="onMouseOut">服饰</a></li>
        <li><a>母婴</a></li>
        <li><a>个护</a></li>
        <li><a @mouseover="onMouseOver" @mouseout="onMouseOut">严选</a></li>
        <li><a>数码</a></li>
        <li><a>运动</a></li>
        <li><a>杂项</a></li>
      </ul>
    </div>

    <div class="nav-right">
      <input type="text" placeholder="搜一搜" />
    </div>

    <div class="xtx-headernav-float" v-show="floatShow">
      <div v-for="item in 5" :key="item">
        <img src="@/assets/img/fresh8.png" alt="" />
        <p>茶咖酒具</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      floatShow: false,
    };
  },
  methods: {
    // mouseover当鼠标悬浮时，显示子菜单
    onMouseOver() {
      this.floatShow = true;
    },
    // mouseout当鼠标离开时，隐藏子菜单
    onMouseOut() {
      this.floatShow = false;
    },
  },
};
</script>

<style lang='less'>
.xtx-headernav {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-width: 1000px;
  .nav-left {
    width: 202px;

    img {
      width: 100%;
    }
  }
  .nav-center {
    display: inline-block;
    width: 60%;
    margin: auto 0;
    li {
      display: inline-block;
      width: 10%;
    }
    li a:hover {
      padding-bottom: 10px;
      color: #5aba9b;
      border-bottom: 2px solid #5aba9b;
      cursor: pointer;
    }
  }
  .nav-right {
    display: inline-block;
    input {
      border: none;
      // border-bottom: 1px solid #ccc;
      box-shadow: 0 3px 3px #ccc;
    }
  }
  .xtx-headernav-float {
    position: absolute;
    width: 99%;
    height: 120px;
    border: 1px solid #ccc;
    box-shadow: 0 -2px 2px #ccc;
    background-color: #fff;
    top: 98%;
    display: flex;
    justify-content: flex-start;
    z-index: 2;
    // display: none;
    div {
      margin-top: 17px;
      margin-left: 20px;
    }

    img {
      width: 55px;
    }
  }
}
</style>